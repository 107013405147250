
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as hierarchy5gJWETNECiMeta } from "/build/packages/front/pages/abilities/[sid]/hierarchy.vue?macro=true";
import { default as historyZnQYCx9oxiMeta } from "/build/packages/front/pages/abilities/[sid]/history.vue?macro=true";
import { default as index7G4bpkKQHPMeta } from "/build/packages/front/pages/abilities/[sid]/index.vue?macro=true";
import { default as _91sid_93PGWPPEbneqMeta } from "/build/packages/front/pages/abilities/[sid].vue?macro=true";
import { default as index7bAAWvYUT7Meta } from "/build/packages/front/pages/abilities/index.vue?macro=true";
import { default as auth_45errorbFdrqIzMZ6Meta } from "/build/packages/front/pages/auth-error.vue?macro=true";
import { default as historym2A1tKu2uXMeta } from "/build/packages/front/pages/business-statuses/[sid]/history.vue?macro=true";
import { default as indexBempwd20edMeta } from "/build/packages/front/pages/business-statuses/[sid]/index.vue?macro=true";
import { default as _91sid_93V65CEMpiK7Meta } from "/build/packages/front/pages/business-statuses/[sid].vue?macro=true";
import { default as indexqXHctYMU5xMeta } from "/build/packages/front/pages/business-statuses/index.vue?macro=true";
import { default as characteristicsqJ0gqiMJLeMeta } from "/build/packages/front/pages/characteristics-field-names/[sid]/characteristics.vue?macro=true";
import { default as historyMcjmHmZoHnMeta } from "/build/packages/front/pages/characteristics-field-names/[sid]/history.vue?macro=true";
import { default as indexS8dVX1Z3J9Meta } from "/build/packages/front/pages/characteristics-field-names/[sid]/index.vue?macro=true";
import { default as _91sid_93UunAjZIMhwMeta } from "/build/packages/front/pages/characteristics-field-names/[sid].vue?macro=true";
import { default as indexHiX8kvKUpkMeta } from "/build/packages/front/pages/characteristics-field-names/index.vue?macro=true";
import { default as characteristicsIjlPtQMszIMeta } from "/build/packages/front/pages/characteristics-groups/[sid]/characteristics.vue?macro=true";
import { default as historyB20LZpYENrMeta } from "/build/packages/front/pages/characteristics-groups/[sid]/history.vue?macro=true";
import { default as indexCZHfdfJ3GqMeta } from "/build/packages/front/pages/characteristics-groups/[sid]/index.vue?macro=true";
import { default as _91sid_93AcvLWSg1cAMeta } from "/build/packages/front/pages/characteristics-groups/[sid].vue?macro=true";
import { default as indexXLHGG5IxC2Meta } from "/build/packages/front/pages/characteristics-groups/index.vue?macro=true";
import { default as data_45sourcests8WakIQ8QMeta } from "/build/packages/front/pages/characteristics/[sid]/data-sources.vue?macro=true";
import { default as fieldsHsQb9yhInZMeta } from "/build/packages/front/pages/characteristics/[sid]/fields.vue?macro=true";
import { default as historyBGbIXygLA4Meta } from "/build/packages/front/pages/characteristics/[sid]/history.vue?macro=true";
import { default as index53r0c6uCntMeta } from "/build/packages/front/pages/characteristics/[sid]/index.vue?macro=true";
import { default as _91sid_93ZgUT2KD9PwMeta } from "/build/packages/front/pages/characteristics/[sid].vue?macro=true";
import { default as indexLGjn18i0zgMeta } from "/build/packages/front/pages/characteristics/index.vue?macro=true";
import { default as indexEpmuM06GR8Meta } from "/build/packages/front/pages/competences/[sid]/index.vue?macro=true";
import { default as usersx8mnZPfWkTMeta } from "/build/packages/front/pages/competences/[sid]/users.vue?macro=true";
import { default as _91sid_93p5AqC9e38lMeta } from "/build/packages/front/pages/competences/[sid].vue?macro=true";
import { default as indexqhZ6PdP99ZMeta } from "/build/packages/front/pages/competences/index.vue?macro=true";
import { default as historyTZivSOHdhiMeta } from "/build/packages/front/pages/countries/[sid]/history.vue?macro=true";
import { default as indexHGdTxdRCxyMeta } from "/build/packages/front/pages/countries/[sid]/index.vue?macro=true";
import { default as _91sid_93jZ9JbPySUYMeta } from "/build/packages/front/pages/countries/[sid].vue?macro=true";
import { default as indexnFNaI6jFKbMeta } from "/build/packages/front/pages/countries/index.vue?macro=true";
import { default as historyJyb9xKSoAmMeta } from "/build/packages/front/pages/currencies/[sid]/history.vue?macro=true";
import { default as indexBWScB3XnzAMeta } from "/build/packages/front/pages/currencies/[sid]/index.vue?macro=true";
import { default as _91sid_93ARfN90r4dhMeta } from "/build/packages/front/pages/currencies/[sid].vue?macro=true";
import { default as index7EhenhlTO4Meta } from "/build/packages/front/pages/currencies/index.vue?macro=true";
import { default as indexwEp2a3SpkeMeta } from "/build/packages/front/pages/dev/index.vue?macro=true";
import { default as historyVpdYpnG0enMeta } from "/build/packages/front/pages/history.vue?macro=true";
import { default as idTvqeRYjjFgMeta } from "/build/packages/front/pages/id.vue?macro=true";
import { default as indexCXasZDPWDyMeta } from "/build/packages/front/pages/index.vue?macro=true";
import { default as detail0G6EznH1ZBMeta } from "/build/packages/front/pages/languages/[sid]/detail.vue?macro=true";
import { default as history0TWSneaK5PMeta } from "/build/packages/front/pages/languages/[sid]/history.vue?macro=true";
import { default as indexdBZ3XenlduMeta } from "/build/packages/front/pages/languages/[sid]/index.vue?macro=true";
import { default as testQp10uI0FK1Meta } from "/build/packages/front/pages/languages/[sid]/test.vue?macro=true";
import { default as _91sid_93JcWfCTRJRcMeta } from "/build/packages/front/pages/languages/[sid].vue?macro=true";
import { default as indexWme96T61ENMeta } from "/build/packages/front/pages/languages/index.vue?macro=true";
import { default as historyh51gQvo7T5Meta } from "/build/packages/front/pages/manufacturers/[sid]/history.vue?macro=true";
import { default as indexCek6bTt0fRMeta } from "/build/packages/front/pages/manufacturers/[sid]/index.vue?macro=true";
import { default as _91sid_93SyxR5oWuStMeta } from "/build/packages/front/pages/manufacturers/[sid].vue?macro=true";
import { default as indexhOPnHXV1RpMeta } from "/build/packages/front/pages/manufacturers/index.vue?macro=true";
import { default as descriptorGYI9DSVhZRMeta } from "/build/packages/front/pages/materials-classes/[sid]/descriptor.vue?macro=true";
import { default as history5G9XqckpeAMeta } from "/build/packages/front/pages/materials-classes/[sid]/history.vue?macro=true";
import { default as indexgE28e3wFSfMeta } from "/build/packages/front/pages/materials-classes/[sid]/index.vue?macro=true";
import { default as _91sid_93LxttC2tdv8Meta } from "/build/packages/front/pages/materials-classes/[sid].vue?macro=true";
import { default as index9uP6P7ZtCdMeta } from "/build/packages/front/pages/materials-classes/index.vue?macro=true";
import { default as historyNty2PeUXJDMeta } from "/build/packages/front/pages/materials-types/[sid]/history.vue?macro=true";
import { default as indexWsOPUv52LlMeta } from "/build/packages/front/pages/materials-types/[sid]/index.vue?macro=true";
import { default as _91sid_93LZdEyMHhGVMeta } from "/build/packages/front/pages/materials-types/[sid].vue?macro=true";
import { default as indexBmQGV4Fuw1Meta } from "/build/packages/front/pages/materials-types/index.vue?macro=true";
import { default as fieldsJnY62Gmu5kMeta } from "/build/packages/front/pages/references/[sid]/fields.vue?macro=true";
import { default as history4QHsIgauSYMeta } from "/build/packages/front/pages/references/[sid]/history.vue?macro=true";
import { default as index37WByMwLxfMeta } from "/build/packages/front/pages/references/[sid]/index.vue?macro=true";
import { default as recordspSOfuaDPgpMeta } from "/build/packages/front/pages/references/[sid]/records.vue?macro=true";
import { default as _91sid_93dDK494oBbzMeta } from "/build/packages/front/pages/references/[sid].vue?macro=true";
import { default as index0rcPB39JyGMeta } from "/build/packages/front/pages/references/index.vue?macro=true";
import { default as baseoqgJOlOjoJMeta } from "/build/packages/front/pages/status-models/[sidVer]/base.vue?macro=true";
import { default as crosswaysI9dDDgZQVpMeta } from "/build/packages/front/pages/status-models/[sidVer]/crossways.vue?macro=true";
import { default as historyQb2qyQU1CBMeta } from "/build/packages/front/pages/status-models/[sidVer]/history.vue?macro=true";
import { default as indexbvaTcu0ESZMeta } from "/build/packages/front/pages/status-models/[sidVer]/index.vue?macro=true";
import { default as map_45listtzdNbI0wqAMeta } from "/build/packages/front/pages/status-models/[sidVer]/map-list.vue?macro=true";
import { default as status_45setVurWtpGB3EMeta } from "/build/packages/front/pages/status-models/[sidVer]/status-set.vue?macro=true";
import { default as _91sidVer_930ItWx17K6EMeta } from "/build/packages/front/pages/status-models/[sidVer].vue?macro=true";
import { default as indexChkkL7Iu61Meta } from "/build/packages/front/pages/status-models/index.vue?macro=true";
import { default as historyo1Rq8Aos4JMeta } from "/build/packages/front/pages/system-entities/[sid]/history.vue?macro=true";
import { default as indexZ572OCfzbvMeta } from "/build/packages/front/pages/system-entities/[sid]/index.vue?macro=true";
import { default as status_45modelsrHsG8YKqlVMeta } from "/build/packages/front/pages/system-entities/[sid]/status-models.vue?macro=true";
import { default as typesBwbHG4a3xCMeta } from "/build/packages/front/pages/system-entities/[sid]/types.vue?macro=true";
import { default as _91sid_93OcZhEAzcIcMeta } from "/build/packages/front/pages/system-entities/[sid].vue?macro=true";
import { default as indexUvTZ5gLYy8Meta } from "/build/packages/front/pages/system-entities/index.vue?macro=true";
import { default as historyfQAss3jsGyMeta } from "/build/packages/front/pages/system-fields/[sid]/history.vue?macro=true";
import { default as indexhjt3zB1U0MMeta } from "/build/packages/front/pages/system-fields/[sid]/index.vue?macro=true";
import { default as _91sid_939AIUdYAa9VMeta } from "/build/packages/front/pages/system-fields/[sid].vue?macro=true";
import { default as indexQ9uimn8u9zMeta } from "/build/packages/front/pages/system-fields/index.vue?macro=true";
import { default as historyUVGzCoylEZMeta } from "/build/packages/front/pages/system-tables/[sid]/history.vue?macro=true";
import { default as indextcxIayrIYvMeta } from "/build/packages/front/pages/system-tables/[sid]/index.vue?macro=true";
import { default as _91sid_93vw4uWe6yguMeta } from "/build/packages/front/pages/system-tables/[sid].vue?macro=true";
import { default as indexMVnoab5koqMeta } from "/build/packages/front/pages/system-tables/index.vue?macro=true";
import { default as index4CbEgXHghwMeta } from "/build/packages/front/pages/test/[id]/index.vue?macro=true";
import { default as tab1ucKwvqOiVZMeta } from "/build/packages/front/pages/test/[id]/tab1.vue?macro=true";
import { default as tab2LKQNWQp8oHMeta } from "/build/packages/front/pages/test/[id]/tab2.vue?macro=true";
import { default as _91id_93w6bUflEDmCMeta } from "/build/packages/front/pages/test/[id].vue?macro=true";
import { default as import_45fpiH40aChVpZEMeta } from "/build/packages/front/pages/test/import-fpi.vue?macro=true";
import { default as import_45ingjZqBTLrhJqMeta } from "/build/packages/front/pages/test/import-ing.vue?macro=true";
import { default as import_45pamrYQlpAFOPtMeta } from "/build/packages/front/pages/test/import-pam.vue?macro=true";
import { default as indexubm2nZJWdIMeta } from "/build/packages/front/pages/test/index.vue?macro=true";
import { default as indexcQjoYGCdtMMeta } from "/build/packages/front/pages/testing-methods-systems/[sid]/index.vue?macro=true";
import { default as testing_45methodsOzVUpavnEYMeta } from "/build/packages/front/pages/testing-methods-systems/[sid]/testing-methods.vue?macro=true";
import { default as _91sid_93ABx0qKDPvBMeta } from "/build/packages/front/pages/testing-methods-systems/[sid].vue?macro=true";
import { default as indexG7eTEecMDJMeta } from "/build/packages/front/pages/testing-methods-systems/index.vue?macro=true";
import { default as baseTUxDy2liBdMeta } from "/build/packages/front/pages/testing-methods/[sidVer]/base.vue?macro=true";
import { default as conditionszJ2xJ8UA1tMeta } from "/build/packages/front/pages/testing-methods/[sidVer]/conditions.vue?macro=true";
import { default as filesVJvbNsNrIGMeta } from "/build/packages/front/pages/testing-methods/[sidVer]/files.vue?macro=true";
import { default as history4kdScTAhcZMeta } from "/build/packages/front/pages/testing-methods/[sidVer]/history.vue?macro=true";
import { default as indexV8udgO8BByMeta } from "/build/packages/front/pages/testing-methods/[sidVer]/index.vue?macro=true";
import { default as manufacturers3xBmvnjiX7Meta } from "/build/packages/front/pages/testing-methods/[sidVer]/manufacturers.vue?macro=true";
import { default as plannerZ5EkCz9FxqMeta } from "/build/packages/front/pages/testing-methods/[sidVer]/planner.vue?macro=true";
import { default as versionsdLvOYc5BUbMeta } from "/build/packages/front/pages/testing-methods/[sidVer]/versions.vue?macro=true";
import { default as _91sidVer_93kBM6XTQ2goMeta } from "/build/packages/front/pages/testing-methods/[sidVer].vue?macro=true";
import { default as indexhLjht7p3m3Meta } from "/build/packages/front/pages/testing-methods/index.vue?macro=true";
import { default as historyTW3tw2A6bxMeta } from "/build/packages/front/pages/translations/[sid]/history.vue?macro=true";
import { default as indexuU18yE5LtxMeta } from "/build/packages/front/pages/translations/[sid]/index.vue?macro=true";
import { default as test_45pluralLVhzXEKQpvMeta } from "/build/packages/front/pages/translations/[sid]/test-plural.vue?macro=true";
import { default as _91sid_93E9Rgme6XL5Meta } from "/build/packages/front/pages/translations/[sid].vue?macro=true";
import { default as importxiG1FqlYbfMeta } from "/build/packages/front/pages/translations/import.vue?macro=true";
import { default as indexAQ9bTiL9ZiMeta } from "/build/packages/front/pages/translations/index.vue?macro=true";
import { default as colorPaletteotVwbchcJNMeta } from "/build/packages/front/pages/ui/colorPalette.vue?macro=true";
import { default as dialogfMBe49SNXrMeta } from "/build/packages/front/pages/ui/dialog.vue?macro=true";
import { default as formysZl1cYaUUMeta } from "/build/packages/front/pages/ui/form.vue?macro=true";
import { default as iconj1spO6zQpxMeta } from "/build/packages/front/pages/ui/icon.vue?macro=true";
import { default as indexbV7xi9aEf9Meta } from "/build/packages/front/pages/ui/index.vue?macro=true";
import { default as list2xAAPuq8l2Meta } from "/build/packages/front/pages/ui/list.vue?macro=true";
import { default as messagezuoSFhD0dlMeta } from "/build/packages/front/pages/ui/message.vue?macro=true";
import { default as treemmzeJj6sFKMeta } from "/build/packages/front/pages/ui/tree.vue?macro=true";
import { default as value_45view_45formattervUWoaOl7wxMeta } from "/build/packages/front/pages/ui/value-view-formatter.vue?macro=true";
import { default as virtual_45scrollBl98EZt9YkMeta } from "/build/packages/front/pages/ui/virtual-scroll.vue?macro=true";
import { default as historymhaze5yAOiMeta } from "/build/packages/front/pages/units-groups/[sid]/history.vue?macro=true";
import { default as indexKmWLcfnhcGMeta } from "/build/packages/front/pages/units-groups/[sid]/index.vue?macro=true";
import { default as units5pDxUeDuyWMeta } from "/build/packages/front/pages/units-groups/[sid]/units.vue?macro=true";
import { default as _91sid_931l5ctMeDl9Meta } from "/build/packages/front/pages/units-groups/[sid].vue?macro=true";
import { default as indexAJgjHoNwOYMeta } from "/build/packages/front/pages/units-groups/index.vue?macro=true";
import { default as historyNe9HRs2HOnMeta } from "/build/packages/front/pages/units/[sid]/history.vue?macro=true";
import { default as indexWPTMdysrYNMeta } from "/build/packages/front/pages/units/[sid]/index.vue?macro=true";
import { default as _91sid_93l9T2Ojbm6DMeta } from "/build/packages/front/pages/units/[sid].vue?macro=true";
import { default as indexWplT8sVyRlMeta } from "/build/packages/front/pages/units/index.vue?macro=true";
import { default as historyRwoKOj83sWMeta } from "/build/packages/front/pages/users/[sid]/history.vue?macro=true";
import { default as indexSzstlCx1uuMeta } from "/build/packages/front/pages/users/[sid]/index.vue?macro=true";
import { default as _91sid_93gPacLnG3nlMeta } from "/build/packages/front/pages/users/[sid].vue?macro=true";
import { default as indexZFFF4rCdkwMeta } from "/build/packages/front/pages/users/index.vue?macro=true";
import { default as historyaTx22maT7FMeta } from "/build/packages/front/pages/workgroups/[sid]/history.vue?macro=true";
import { default as indexFFlcJGxcjYMeta } from "/build/packages/front/pages/workgroups/[sid]/index.vue?macro=true";
import { default as usersicj5SNGNetMeta } from "/build/packages/front/pages/workgroups/[sid]/users.vue?macro=true";
import { default as _91sid_93EgZ6EIVOp6Meta } from "/build/packages/front/pages/workgroups/[sid].vue?macro=true";
import { default as indexToZDooGBlsMeta } from "/build/packages/front/pages/workgroups/index.vue?macro=true";
export default [
  {
    name: _91sid_93PGWPPEbneqMeta?.name,
    path: "/abilities/:sid()",
    component: () => import("/build/packages/front/pages/abilities/[sid].vue"),
    children: [
  {
    name: "abilities-sid-hierarchy",
    path: "hierarchy",
    component: () => import("/build/packages/front/pages/abilities/[sid]/hierarchy.vue")
  },
  {
    name: "abilities-sid-history",
    path: "history",
    component: () => import("/build/packages/front/pages/abilities/[sid]/history.vue")
  },
  {
    name: "abilities-sid",
    path: "",
    component: () => import("/build/packages/front/pages/abilities/[sid]/index.vue")
  }
]
  },
  {
    name: "abilities",
    path: "/abilities",
    component: () => import("/build/packages/front/pages/abilities/index.vue")
  },
  {
    name: "auth-error",
    path: "/auth-error",
    meta: auth_45errorbFdrqIzMZ6Meta || {},
    component: () => import("/build/packages/front/pages/auth-error.vue")
  },
  {
    name: _91sid_93V65CEMpiK7Meta?.name,
    path: "/business-statuses/:sid()",
    component: () => import("/build/packages/front/pages/business-statuses/[sid].vue"),
    children: [
  {
    name: "business-statuses-sid-history",
    path: "history",
    component: () => import("/build/packages/front/pages/business-statuses/[sid]/history.vue")
  },
  {
    name: "business-statuses-sid",
    path: "",
    component: () => import("/build/packages/front/pages/business-statuses/[sid]/index.vue")
  }
]
  },
  {
    name: "business-statuses",
    path: "/business-statuses",
    component: () => import("/build/packages/front/pages/business-statuses/index.vue")
  },
  {
    name: _91sid_93UunAjZIMhwMeta?.name,
    path: "/characteristics-field-names/:sid()",
    component: () => import("/build/packages/front/pages/characteristics-field-names/[sid].vue"),
    children: [
  {
    name: "characteristics-field-names-sid-characteristics",
    path: "characteristics",
    component: () => import("/build/packages/front/pages/characteristics-field-names/[sid]/characteristics.vue")
  },
  {
    name: "characteristics-field-names-sid-history",
    path: "history",
    component: () => import("/build/packages/front/pages/characteristics-field-names/[sid]/history.vue")
  },
  {
    name: "characteristics-field-names-sid",
    path: "",
    component: () => import("/build/packages/front/pages/characteristics-field-names/[sid]/index.vue")
  }
]
  },
  {
    name: "characteristics-field-names",
    path: "/characteristics-field-names",
    component: () => import("/build/packages/front/pages/characteristics-field-names/index.vue")
  },
  {
    name: _91sid_93AcvLWSg1cAMeta?.name,
    path: "/characteristics-groups/:sid()",
    component: () => import("/build/packages/front/pages/characteristics-groups/[sid].vue"),
    children: [
  {
    name: "characteristics-groups-sid-characteristics",
    path: "characteristics",
    component: () => import("/build/packages/front/pages/characteristics-groups/[sid]/characteristics.vue")
  },
  {
    name: "characteristics-groups-sid-history",
    path: "history",
    component: () => import("/build/packages/front/pages/characteristics-groups/[sid]/history.vue")
  },
  {
    name: "characteristics-groups-sid",
    path: "",
    component: () => import("/build/packages/front/pages/characteristics-groups/[sid]/index.vue")
  }
]
  },
  {
    name: "characteristics-groups",
    path: "/characteristics-groups",
    component: () => import("/build/packages/front/pages/characteristics-groups/index.vue")
  },
  {
    name: _91sid_93ZgUT2KD9PwMeta?.name,
    path: "/characteristics/:sid()",
    component: () => import("/build/packages/front/pages/characteristics/[sid].vue"),
    children: [
  {
    name: "characteristics-sid-data-sources",
    path: "data-sources",
    component: () => import("/build/packages/front/pages/characteristics/[sid]/data-sources.vue")
  },
  {
    name: "characteristics-sid-fields",
    path: "fields",
    component: () => import("/build/packages/front/pages/characteristics/[sid]/fields.vue")
  },
  {
    name: "characteristics-sid-history",
    path: "history",
    component: () => import("/build/packages/front/pages/characteristics/[sid]/history.vue")
  },
  {
    name: "characteristics-sid",
    path: "",
    component: () => import("/build/packages/front/pages/characteristics/[sid]/index.vue")
  }
]
  },
  {
    name: "characteristics",
    path: "/characteristics",
    component: () => import("/build/packages/front/pages/characteristics/index.vue")
  },
  {
    name: _91sid_93p5AqC9e38lMeta?.name,
    path: "/competences/:sid()",
    component: () => import("/build/packages/front/pages/competences/[sid].vue"),
    children: [
  {
    name: "competences-sid",
    path: "",
    component: () => import("/build/packages/front/pages/competences/[sid]/index.vue")
  },
  {
    name: "competences-sid-users",
    path: "users",
    component: () => import("/build/packages/front/pages/competences/[sid]/users.vue")
  }
]
  },
  {
    name: "competences",
    path: "/competences",
    component: () => import("/build/packages/front/pages/competences/index.vue")
  },
  {
    name: _91sid_93jZ9JbPySUYMeta?.name,
    path: "/countries/:sid()",
    component: () => import("/build/packages/front/pages/countries/[sid].vue"),
    children: [
  {
    name: "countries-sid-history",
    path: "history",
    component: () => import("/build/packages/front/pages/countries/[sid]/history.vue")
  },
  {
    name: "countries-sid",
    path: "",
    component: () => import("/build/packages/front/pages/countries/[sid]/index.vue")
  }
]
  },
  {
    name: "countries",
    path: "/countries",
    component: () => import("/build/packages/front/pages/countries/index.vue")
  },
  {
    name: _91sid_93ARfN90r4dhMeta?.name,
    path: "/currencies/:sid()",
    component: () => import("/build/packages/front/pages/currencies/[sid].vue"),
    children: [
  {
    name: "currencies-sid-history",
    path: "history",
    component: () => import("/build/packages/front/pages/currencies/[sid]/history.vue")
  },
  {
    name: "currencies-sid",
    path: "",
    component: () => import("/build/packages/front/pages/currencies/[sid]/index.vue")
  }
]
  },
  {
    name: "currencies",
    path: "/currencies",
    component: () => import("/build/packages/front/pages/currencies/index.vue")
  },
  {
    name: "dev",
    path: "/dev",
    component: () => import("/build/packages/front/pages/dev/index.vue")
  },
  {
    name: "history",
    path: "/history",
    component: () => import("/build/packages/front/pages/history.vue")
  },
  {
    name: "id",
    path: "/id",
    component: () => import("/build/packages/front/pages/id.vue")
  },
  {
    name: "index",
    path: "/",
    meta: indexCXasZDPWDyMeta || {},
    component: () => import("/build/packages/front/pages/index.vue")
  },
  {
    name: _91sid_93JcWfCTRJRcMeta?.name,
    path: "/languages/:sid()",
    component: () => import("/build/packages/front/pages/languages/[sid].vue"),
    children: [
  {
    name: "languages-sid-detail",
    path: "detail",
    component: () => import("/build/packages/front/pages/languages/[sid]/detail.vue")
  },
  {
    name: "languages-sid-history",
    path: "history",
    component: () => import("/build/packages/front/pages/languages/[sid]/history.vue")
  },
  {
    name: "languages-sid",
    path: "",
    component: () => import("/build/packages/front/pages/languages/[sid]/index.vue")
  },
  {
    name: "languages-sid-test",
    path: "test",
    component: () => import("/build/packages/front/pages/languages/[sid]/test.vue")
  }
]
  },
  {
    name: "languages",
    path: "/languages",
    component: () => import("/build/packages/front/pages/languages/index.vue")
  },
  {
    name: _91sid_93SyxR5oWuStMeta?.name,
    path: "/manufacturers/:sid()",
    component: () => import("/build/packages/front/pages/manufacturers/[sid].vue"),
    children: [
  {
    name: "manufacturers-sid-history",
    path: "history",
    component: () => import("/build/packages/front/pages/manufacturers/[sid]/history.vue")
  },
  {
    name: "manufacturers-sid",
    path: "",
    component: () => import("/build/packages/front/pages/manufacturers/[sid]/index.vue")
  }
]
  },
  {
    name: "manufacturers",
    path: "/manufacturers",
    component: () => import("/build/packages/front/pages/manufacturers/index.vue")
  },
  {
    name: _91sid_93LxttC2tdv8Meta?.name,
    path: "/materials-classes/:sid()",
    component: () => import("/build/packages/front/pages/materials-classes/[sid].vue"),
    children: [
  {
    name: "materials-classes-sid-descriptor",
    path: "descriptor",
    component: () => import("/build/packages/front/pages/materials-classes/[sid]/descriptor.vue")
  },
  {
    name: "materials-classes-sid-history",
    path: "history",
    component: () => import("/build/packages/front/pages/materials-classes/[sid]/history.vue")
  },
  {
    name: "materials-classes-sid",
    path: "",
    component: () => import("/build/packages/front/pages/materials-classes/[sid]/index.vue")
  }
]
  },
  {
    name: "materials-classes",
    path: "/materials-classes",
    component: () => import("/build/packages/front/pages/materials-classes/index.vue")
  },
  {
    name: _91sid_93LZdEyMHhGVMeta?.name,
    path: "/materials-types/:sid()",
    component: () => import("/build/packages/front/pages/materials-types/[sid].vue"),
    children: [
  {
    name: "materials-types-sid-history",
    path: "history",
    component: () => import("/build/packages/front/pages/materials-types/[sid]/history.vue")
  },
  {
    name: "materials-types-sid",
    path: "",
    component: () => import("/build/packages/front/pages/materials-types/[sid]/index.vue")
  }
]
  },
  {
    name: "materials-types",
    path: "/materials-types",
    component: () => import("/build/packages/front/pages/materials-types/index.vue")
  },
  {
    name: _91sid_93dDK494oBbzMeta?.name,
    path: "/references/:sid()",
    component: () => import("/build/packages/front/pages/references/[sid].vue"),
    children: [
  {
    name: "references-sid-fields",
    path: "fields",
    component: () => import("/build/packages/front/pages/references/[sid]/fields.vue")
  },
  {
    name: "references-sid-history",
    path: "history",
    component: () => import("/build/packages/front/pages/references/[sid]/history.vue")
  },
  {
    name: "references-sid",
    path: "",
    component: () => import("/build/packages/front/pages/references/[sid]/index.vue")
  },
  {
    name: "references-sid-records",
    path: "records",
    component: () => import("/build/packages/front/pages/references/[sid]/records.vue")
  }
]
  },
  {
    name: "references",
    path: "/references",
    component: () => import("/build/packages/front/pages/references/index.vue")
  },
  {
    name: _91sidVer_930ItWx17K6EMeta?.name,
    path: "/status-models/:sidVer()",
    component: () => import("/build/packages/front/pages/status-models/[sidVer].vue"),
    children: [
  {
    name: "status-models-sidVer-base",
    path: "base",
    component: () => import("/build/packages/front/pages/status-models/[sidVer]/base.vue")
  },
  {
    name: "status-models-sidVer-crossways",
    path: "crossways",
    component: () => import("/build/packages/front/pages/status-models/[sidVer]/crossways.vue")
  },
  {
    name: "status-models-sidVer-history",
    path: "history",
    component: () => import("/build/packages/front/pages/status-models/[sidVer]/history.vue")
  },
  {
    name: "status-models-sidVer",
    path: "",
    component: () => import("/build/packages/front/pages/status-models/[sidVer]/index.vue")
  },
  {
    name: "status-models-sidVer-map-list",
    path: "map-list",
    component: () => import("/build/packages/front/pages/status-models/[sidVer]/map-list.vue")
  },
  {
    name: "status-models-sidVer-status-set",
    path: "status-set",
    component: () => import("/build/packages/front/pages/status-models/[sidVer]/status-set.vue")
  }
]
  },
  {
    name: "status-models",
    path: "/status-models",
    component: () => import("/build/packages/front/pages/status-models/index.vue")
  },
  {
    name: _91sid_93OcZhEAzcIcMeta?.name,
    path: "/system-entities/:sid()",
    component: () => import("/build/packages/front/pages/system-entities/[sid].vue"),
    children: [
  {
    name: "system-entities-sid-history",
    path: "history",
    component: () => import("/build/packages/front/pages/system-entities/[sid]/history.vue")
  },
  {
    name: "system-entities-sid",
    path: "",
    component: () => import("/build/packages/front/pages/system-entities/[sid]/index.vue")
  },
  {
    name: "system-entities-sid-status-models",
    path: "status-models",
    component: () => import("/build/packages/front/pages/system-entities/[sid]/status-models.vue")
  },
  {
    name: "system-entities-sid-types",
    path: "types",
    component: () => import("/build/packages/front/pages/system-entities/[sid]/types.vue")
  }
]
  },
  {
    name: "system-entities",
    path: "/system-entities",
    component: () => import("/build/packages/front/pages/system-entities/index.vue")
  },
  {
    name: _91sid_939AIUdYAa9VMeta?.name,
    path: "/system-fields/:sid()",
    component: () => import("/build/packages/front/pages/system-fields/[sid].vue"),
    children: [
  {
    name: "system-fields-sid-history",
    path: "history",
    component: () => import("/build/packages/front/pages/system-fields/[sid]/history.vue")
  },
  {
    name: "system-fields-sid",
    path: "",
    component: () => import("/build/packages/front/pages/system-fields/[sid]/index.vue")
  }
]
  },
  {
    name: "system-fields",
    path: "/system-fields",
    component: () => import("/build/packages/front/pages/system-fields/index.vue")
  },
  {
    name: _91sid_93vw4uWe6yguMeta?.name,
    path: "/system-tables/:sid()",
    component: () => import("/build/packages/front/pages/system-tables/[sid].vue"),
    children: [
  {
    name: "system-tables-sid-history",
    path: "history",
    component: () => import("/build/packages/front/pages/system-tables/[sid]/history.vue")
  },
  {
    name: "system-tables-sid",
    path: "",
    component: () => import("/build/packages/front/pages/system-tables/[sid]/index.vue")
  }
]
  },
  {
    name: "system-tables",
    path: "/system-tables",
    component: () => import("/build/packages/front/pages/system-tables/index.vue")
  },
  {
    name: _91id_93w6bUflEDmCMeta?.name,
    path: "/test/:id()",
    component: () => import("/build/packages/front/pages/test/[id].vue"),
    children: [
  {
    name: "test-id",
    path: "",
    component: () => import("/build/packages/front/pages/test/[id]/index.vue")
  },
  {
    name: "test-id-tab1",
    path: "tab1",
    component: () => import("/build/packages/front/pages/test/[id]/tab1.vue")
  },
  {
    name: "test-id-tab2",
    path: "tab2",
    component: () => import("/build/packages/front/pages/test/[id]/tab2.vue")
  }
]
  },
  {
    name: "test-import-fpi",
    path: "/test/import-fpi",
    component: () => import("/build/packages/front/pages/test/import-fpi.vue")
  },
  {
    name: "test-import-ing",
    path: "/test/import-ing",
    component: () => import("/build/packages/front/pages/test/import-ing.vue")
  },
  {
    name: "test-import-pam",
    path: "/test/import-pam",
    component: () => import("/build/packages/front/pages/test/import-pam.vue")
  },
  {
    name: "test",
    path: "/test",
    component: () => import("/build/packages/front/pages/test/index.vue")
  },
  {
    name: _91sid_93ABx0qKDPvBMeta?.name,
    path: "/testing-methods-systems/:sid()",
    component: () => import("/build/packages/front/pages/testing-methods-systems/[sid].vue"),
    children: [
  {
    name: "testing-methods-systems-sid",
    path: "",
    component: () => import("/build/packages/front/pages/testing-methods-systems/[sid]/index.vue")
  },
  {
    name: "testing-methods-systems-sid-testing-methods",
    path: "testing-methods",
    component: () => import("/build/packages/front/pages/testing-methods-systems/[sid]/testing-methods.vue")
  }
]
  },
  {
    name: "testing-methods-systems",
    path: "/testing-methods-systems",
    component: () => import("/build/packages/front/pages/testing-methods-systems/index.vue")
  },
  {
    name: _91sidVer_93kBM6XTQ2goMeta?.name,
    path: "/testing-methods/:sidVer()",
    component: () => import("/build/packages/front/pages/testing-methods/[sidVer].vue"),
    children: [
  {
    name: "testing-methods-sidVer-base",
    path: "base",
    component: () => import("/build/packages/front/pages/testing-methods/[sidVer]/base.vue")
  },
  {
    name: "testing-methods-sidVer-conditions",
    path: "conditions",
    component: () => import("/build/packages/front/pages/testing-methods/[sidVer]/conditions.vue")
  },
  {
    name: "testing-methods-sidVer-files",
    path: "files",
    component: () => import("/build/packages/front/pages/testing-methods/[sidVer]/files.vue")
  },
  {
    name: "testing-methods-sidVer-history",
    path: "history",
    component: () => import("/build/packages/front/pages/testing-methods/[sidVer]/history.vue")
  },
  {
    name: "testing-methods-sidVer",
    path: "",
    component: () => import("/build/packages/front/pages/testing-methods/[sidVer]/index.vue")
  },
  {
    name: "testing-methods-sidVer-manufacturers",
    path: "manufacturers",
    component: () => import("/build/packages/front/pages/testing-methods/[sidVer]/manufacturers.vue")
  },
  {
    name: "testing-methods-sidVer-planner",
    path: "planner",
    component: () => import("/build/packages/front/pages/testing-methods/[sidVer]/planner.vue")
  },
  {
    name: "testing-methods-sidVer-versions",
    path: "versions",
    component: () => import("/build/packages/front/pages/testing-methods/[sidVer]/versions.vue")
  }
]
  },
  {
    name: "testing-methods",
    path: "/testing-methods",
    component: () => import("/build/packages/front/pages/testing-methods/index.vue")
  },
  {
    name: _91sid_93E9Rgme6XL5Meta?.name,
    path: "/translations/:sid()",
    component: () => import("/build/packages/front/pages/translations/[sid].vue"),
    children: [
  {
    name: "translations-sid-history",
    path: "history",
    component: () => import("/build/packages/front/pages/translations/[sid]/history.vue")
  },
  {
    name: "translations-sid",
    path: "",
    component: () => import("/build/packages/front/pages/translations/[sid]/index.vue")
  },
  {
    name: "translations-sid-test-plural",
    path: "test-plural",
    component: () => import("/build/packages/front/pages/translations/[sid]/test-plural.vue")
  }
]
  },
  {
    name: "translations-import",
    path: "/translations/import",
    component: () => import("/build/packages/front/pages/translations/import.vue")
  },
  {
    name: "translations",
    path: "/translations",
    component: () => import("/build/packages/front/pages/translations/index.vue")
  },
  {
    name: "ui-colorPalette",
    path: "/ui/colorPalette",
    component: () => import("/build/packages/front/pages/ui/colorPalette.vue")
  },
  {
    name: "ui-dialog",
    path: "/ui/dialog",
    component: () => import("/build/packages/front/pages/ui/dialog.vue")
  },
  {
    name: "ui-form",
    path: "/ui/form",
    component: () => import("/build/packages/front/pages/ui/form.vue")
  },
  {
    name: "ui-icon",
    path: "/ui/icon",
    component: () => import("/build/packages/front/pages/ui/icon.vue")
  },
  {
    name: "ui",
    path: "/ui",
    component: () => import("/build/packages/front/pages/ui/index.vue")
  },
  {
    name: "ui-list",
    path: "/ui/list",
    component: () => import("/build/packages/front/pages/ui/list.vue")
  },
  {
    name: "ui-message",
    path: "/ui/message",
    component: () => import("/build/packages/front/pages/ui/message.vue")
  },
  {
    name: "ui-tree",
    path: "/ui/tree",
    component: () => import("/build/packages/front/pages/ui/tree.vue")
  },
  {
    name: "ui-value-view-formatter",
    path: "/ui/value-view-formatter",
    component: () => import("/build/packages/front/pages/ui/value-view-formatter.vue")
  },
  {
    name: "ui-virtual-scroll",
    path: "/ui/virtual-scroll",
    component: () => import("/build/packages/front/pages/ui/virtual-scroll.vue")
  },
  {
    name: _91sid_931l5ctMeDl9Meta?.name,
    path: "/units-groups/:sid()",
    component: () => import("/build/packages/front/pages/units-groups/[sid].vue"),
    children: [
  {
    name: "units-groups-sid-history",
    path: "history",
    component: () => import("/build/packages/front/pages/units-groups/[sid]/history.vue")
  },
  {
    name: "units-groups-sid",
    path: "",
    component: () => import("/build/packages/front/pages/units-groups/[sid]/index.vue")
  },
  {
    name: "units-groups-sid-units",
    path: "units",
    component: () => import("/build/packages/front/pages/units-groups/[sid]/units.vue")
  }
]
  },
  {
    name: "units-groups",
    path: "/units-groups",
    component: () => import("/build/packages/front/pages/units-groups/index.vue")
  },
  {
    name: _91sid_93l9T2Ojbm6DMeta?.name,
    path: "/units/:sid()",
    component: () => import("/build/packages/front/pages/units/[sid].vue"),
    children: [
  {
    name: "units-sid-history",
    path: "history",
    component: () => import("/build/packages/front/pages/units/[sid]/history.vue")
  },
  {
    name: "units-sid",
    path: "",
    component: () => import("/build/packages/front/pages/units/[sid]/index.vue")
  }
]
  },
  {
    name: "units",
    path: "/units",
    component: () => import("/build/packages/front/pages/units/index.vue")
  },
  {
    name: _91sid_93gPacLnG3nlMeta?.name,
    path: "/users/:sid()",
    component: () => import("/build/packages/front/pages/users/[sid].vue"),
    children: [
  {
    name: "users-sid-history",
    path: "history",
    component: () => import("/build/packages/front/pages/users/[sid]/history.vue")
  },
  {
    name: "users-sid",
    path: "",
    component: () => import("/build/packages/front/pages/users/[sid]/index.vue")
  }
]
  },
  {
    name: "users",
    path: "/users",
    component: () => import("/build/packages/front/pages/users/index.vue")
  },
  {
    name: _91sid_93EgZ6EIVOp6Meta?.name,
    path: "/workgroups/:sid()",
    component: () => import("/build/packages/front/pages/workgroups/[sid].vue"),
    children: [
  {
    name: "workgroups-sid-history",
    path: "history",
    component: () => import("/build/packages/front/pages/workgroups/[sid]/history.vue")
  },
  {
    name: "workgroups-sid",
    path: "",
    component: () => import("/build/packages/front/pages/workgroups/[sid]/index.vue")
  },
  {
    name: "workgroups-sid-users",
    path: "users",
    component: () => import("/build/packages/front/pages/workgroups/[sid]/users.vue")
  }
]
  },
  {
    name: "workgroups",
    path: "/workgroups",
    component: () => import("/build/packages/front/pages/workgroups/index.vue")
  }
]