<template>
    <Transition name="r-modal-tr">
        <div v-show="tagName" class="r-modal">
            <div class="r-modal-overlay" @click="localClose" />
            <div class="r-modal-wrap" :class="compClassName">
                <div class="r-modal--paper">
                    <RModalHeader v-if="modal && (modal.title || modal.icon)" :modal="modal" />
                    <component :is="modal?.component" ref="comp" class="r-modal--component " v-bind="modal?.props" />
                </div>

                <div class="r-modal--right" @click="localClose">
                    <RBtn
                        :wait="modal?.waiting"
                        mode="solid"
                        icon="cancel"
                        size="big"
                        color="white"
                        class="r-modal--close-key"
                        @click.stop="localClose"
                    />
                </div>
            </div>
        </div>
    </Transition>
</template>

<script setup lang="ts">
import { useRoute } from '#imports'
import { computed, onMounted, onUnmounted, ref, watch } from 'vue'
import { useModal } from '~/components/UiKit'
import { useBase } from '~/components/UiKit/base/composables/useBase'
import RModalHeader from '~/components/UiKit/modal/RModalHeader.vue'

/**
 * Use
 */
const { modal, close } = useModal()
const { toKebabCase } = useBase()

const route = useRoute()

/**
 * Data
 */
const currentSize = ref('')
const comp = ref<any>(null)

/**
 * Computed
 */
const tagName = computed(() => {
    if (!modal.value?.component) {
        return ''
    }
    let name = modal.value.component.__name
    if (!name && modal.value.component.__file) {
        let tmp = modal.value.component.__file.split('/')
        name = (tmp[tmp.length - 1].split('.')[0])
    }
    if (!name) {
        throw new Error('Cannot find name of component')
    }
    return name
})
const compClassName = computed(() => {
    const list = [`r-modal--size-${currentSize.value}`]
    if (tagName?.value) {
        list.push('open', `r-modal-${toKebabCase(tagName.value)}`)
    }
    return list.join(' ')
})

/**
 * Watch
 */
watch(() => modal.value?.size, value => {
    if (!value || !modal.value?.component) {
        return
    }
    currentSize.value = value
})
watch(() => route.path, localClose)

/**
 * Hooks
 */
onMounted(() => {
    document.addEventListener('keydown', onKeydown)
})
onUnmounted(() => {
    document.removeEventListener('keydown', onKeydown)
})

/**
 * Methods
 */
function onKeydown(e: KeyboardEvent) {
    if (!modal.value) {
        return
    }
    if (e.key !== 'Escape') {
        return
    }
    localClose()
}

function localClose() {
    if (modal.value?.waiting || !modal.value?.component) {
        return
    }
    // данные из defineExpose
    const defineExposeData = { ...comp.value }
    close(Object.keys(defineExposeData).length ? defineExposeData : undefined)
}
</script>
