import type { BootData } from 'back/src/apps/api-ui/routes/001_boot'
import { defineNuxtRouteMiddleware, navigateTo } from '#app'
import dayjs from 'dayjs'
import { useFetchCall } from '~/composables/useFetchCall'
import { useAppStore } from '~/stores/app'
import { useConfigStore } from '~/stores/config'
import { useUserStore } from '~/stores/user'
import { loadFieldsConfig } from '~/utils/fieldsConfig'
import { systemLanguagesLoad } from '~/utils/languages'

let boot = true
export default defineNuxtRouteMiddleware(async (to, from) => {
    if (!boot) {
        return
    }
    if (to.name === 'auth-error') {
        return
    }

    const appStore = useAppStore()
    const data = await useFetchCall<BootData, any>('/boot', {})
    useConfigStore().boot(data.config)
    useUserStore().$patch(data.user)
    appStore.$patch({
        appLanguages: data.languages,
        langData: data.user.settings.langData,
        backVersion: data.version,
    })
    appStore.setLangUI(data.user.settings.langUi)
    dayjs.locale(data.user.settings.langUi)

    systemLanguagesLoad(data.languages)
    loadFieldsConfig(data.dfc)
    boot = false

    // load SPA version
    fetch('/version.txt')
        .then(r => r.text())
        .then(frontVersion => {
            appStore.$patch({ frontVersion })
        })

    // todo? setup user load every 15m

    const returnToAfterAuth = sessionStorage?.getItem('returnToAfterAuth')
    if (returnToAfterAuth) {
        sessionStorage.removeItem('returnToAfterAuth')
        return navigateTo(returnToAfterAuth)
    }
})
