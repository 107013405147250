import { useFetchCall, useRoute } from '#imports'
import { defineNuxtPlugin } from 'nuxt/app'

// не записывать промежуточные переходы длительностью менее 2 сек
const debounceDelay = 2_000
let debounceTimer: NodeJS.Timer | undefined
// не записывать повторный визит на один адрес
let lastPath = ''

const excludeSet = new Set(['auth-error', 'dev', 'history', 'ui', 'translations'])

function visit(pathL1: string, pathL2: string, title: string) {
    const path = `${pathL1}/${pathL2}`
    if (pathL1 && !excludeSet.has(pathL1) && lastPath !== path) {
        useFetchCall('/visitsCreate', { pathL1, pathL2, title }, { background: true }).catch(e => {
            console.error('Visit track error', e)
        })
        lastPath = path
    }
}

export default defineNuxtPlugin(nuxtApp => {
    nuxtApp.hook('page:loading:end', () => {
        clearTimeout(debounceTimer)
        const [,pathL1, pathL2 = ''] = useRoute().path.split('/', 4)
        const { title = '' } = document
        debounceTimer = setTimeout(() => visit(pathL1, pathL2, title), debounceDelay)
    })
})
